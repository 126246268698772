import * as React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"

const BuddiMenuWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 100;
`

const BuddiMenu = () => {
  return (
    <>
      <Helmet>
        <script
          src="https://app.buddi.io/js/external-ropis.js"
          authId="1133"
          async
        ></script>
      </Helmet>
      <BuddiMenuWrapper id="buddi-em-menu"></BuddiMenuWrapper>
    </>
  )
}

export default BuddiMenu
